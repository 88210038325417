<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import { dataMaster, masterComputed, masterMethods, nameMaster, checkPermission, keyMaster } from '@/state/helpers';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { maxLength, required } from 'vuelidate/lib/validators';
import Footer from '@/components/Footer/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import { showMessage } from '@/utils/messages';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '規約用条件設定',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Multiselect,
        Footer,
        LoadingIcon
    },
    data() {
        return {
            index: 1,
            loading: false,
            ruleClose: {},
            ruleOpen: {},
            listDataMasterCampaignType: [],
            listDataMasterCampaignTypeClose: [],
            listDataMasterCampaignTypeOpen: [],
            objectData: {
                key: this.$route.query.key,
                id: '',
                values: []
            },
            dataDefault: [],
            form: {
                rule: '',
                type: '',
                details: [],
                platforms: []
            },
            listType: [
                { id: 1, name: '共通' },
                { id: 2, name: 'クローズ' },
                { id: 3, name: '応募種別' }
            ],
            listData: [],
            listDataRule: [],
            dataSelect: {},
            data: {
                rule_details: []
            },
            object: {},
            rule: {},
            dataMaster: dataMaster,
            nameMaster: nameMaster,
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            checkNull: false,
            isLoading: false
        };
    },
    validations: {
        form: {
            rule: { required, maxLength: maxLength(255) },
            type: { required }
        }
    },
    computed: {
        ...masterComputed,
        filteredOptions() {
            return (index) => {
                const selectedValues = this.listData.filter((value, i) => i !== index);
                let arr = JSON.parse(JSON.stringify(this.listDataRule));
                arr.forEach((option, idx) => {
                    let i = selectedValues.findIndex((x) => x.id === option.id);
                    if (i >= 0) {
                        arr[idx].$isDisabled = true;
                    } else {
                        arr[idx].$isDisabled = false;
                    }
                });
                return arr;
            };
        }
    },
    mounted() {
        this.getListMasterCampaignType();
        this.getListMaster();
        this.getDetail();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        getDetail() {
            if (this.$route.query.id) {
                let data = {
                    id: this.$route.query.id
                };
                this.isLoading = true;
                this.detailMasterRule1(data).then((data) => {
                    if (data.code == 200) {
                        this.listData = data.data.details;
                        this.form = data.data;
                        this.form.platforms.forEach((item) => {
                            let indexClose = this.listDataMasterCampaignTypeClose.findIndex((x) => x.id == item.id);
                            if (indexClose >= 0) this.ruleClose = item;

                            let indexOpen = this.listDataMasterCampaignTypeOpen.findIndex((x) => x.id == item.id);
                            if (indexOpen >= 0) this.ruleOpen = item;
                        });
                        this.listData.forEach((item) => {
                            item.detail = item.detail.replace(/<\/?[^>]+(>|$)/g, '');
                            this.data.rule_details.push(item.id);
                        });
                    }
                    this.isLoading = false;
                });
            }
        },

        getListMasterCampaignType() {
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                this.listDataMasterCampaignType = data;
                data.forEach((item) => {
                    if (item.value == 'クローズ') {
                        this.listDataMasterCampaignTypeClose = item.data;
                    } else {
                        this.listDataMasterCampaignTypeOpen = item.data;
                    }
                });
            });
        },
        getListMaster() {
            this.loading = true;
            this.getAllMasterRule().then((data) => {
                if (data.code == 200) {
                    this.loading = false;
                    data.data.forEach((item) => {
                        item.detail = item.detail.replace(/<\/?[^>]+(>|$)/g, '');
                    });
                    this.listDataRule = data.data;
                }
            });
        },
        goEdit() {
            this.$router.push({ path: '/master/condition-rule' });
        },
        formSubmit() {
            this.submitted = true;
            this.$v.form.$touch();
            if (this.$v.form.$invalid) {
                return;
            } else {
                this.register();
            }
        },
        register() {
            let data = JSON.parse(JSON.stringify(this.form));
            data.details = [];
            this.listData.forEach((item) => {
                data.details.push(item.id);
            });
            data.platforms = [];
            if (this.ruleOpen && this.ruleOpen.id) {
                data.platforms.push(this.ruleOpen.id);
            }
            if (this.ruleClose && this.ruleClose.id) {
                data.platforms.push(this.ruleClose.id);
            }
            if (this.$route.query.id) {
                this.updateMasterRuleDetail1(data).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, '保存されました。');
                        this.getDetail();
                    }

                    if (data.code == 422) {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                    }
                });
            } else {
                this.createMasterRuleDetail1(data).then((data) => {
                    if (data.code == 200) {
                        this.$router
                            .push({
                                path: '/master/condition-rule-edit',
                                query: {
                                    id: data.data.id
                                }
                            })
                            .then(() => {
                                showMessage(data.code, this.$bvToast, '登録されました。');
                                this.getDetail();
                            });
                    }

                    if (data.code == 422) {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                    }
                });
            }
        },
        changeData() {
            this.data.rule_details = [];
            this.listData.forEach((item) => {
                this.data.rule_details.push(item.id);
            });
            let object = {
                rule_id: this.$route.query.rule_id,
                id: this.$route.query.id,
                data: this.data
            };
            this.updateMasterRuleDetail1(object).then((data) => {
                if (data.code == 200) {
                    this.getDetail();
                    showMessage(data.code, this.$bvToast, '更新されました。');
                }
            });
        },
        edit1(item, key) {
            if (!item.disabled) {
                this.listData[key].disabled = true;
                this.listData = [].concat(this.listData);
            } else {
                this.changeData(key);
            }
        },
        add() {
            if (this.dataSelect.id) {
                this.listData.push(this.dataSelect);
                this.dataSelect = {};
            }
        },
        changeClose(key) {
            this.listData[key].disabled = true;
            this.listData = [].concat(this.listData);
        },
        deleteObject(k) {
            Swal.fire({
                title: '削除してもよろしいですか。',
                text: '',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#e8ecf4',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    if (k >= 0) {
                        this.listData.splice(k, 1);
                    }
                }
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!isLoading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3>{{ object.rule_title }}</h3>
                    </div>
                    <div class="card-body" :key="index">
                        <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                            <div class="row form-group">
                                <label for="" class="col-sm-3">タイトル<span class="text-danger">*</span></label>

                                <div class="col-sm-9">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="form.rule"
                                        :class="{
                                            'is-invalid': submitted && $v.form.rule.$error
                                        }"
                                    />
                                    <div v-if="submitted && $v.form.rule.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.rule.required">{{
                                            $t('validateField.required', {
                                                field: 'タイトル'
                                            })
                                        }}</span>
                                        <span v-if="!$v.form.rule.maxLength">
                                            {{
                                                $t('validateField.max255', {
                                                    field: 'タイトル'
                                                })
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <label for="" class="col-sm-3">種別<span class="text-danger">*</span></label>

                                <div class="col-sm-9">
                                    <div
                                        class="d-flex flex-wrap"
                                        :class="{
                                            'is-invalid': submitted && $v.form.type.$error
                                        }"
                                    >
                                        <div class="custom-control custom-radio mr-4 mb-1" v-for="item in listType" :key="item.id + 's'">
                                            <input
                                                type="radio"
                                                v-model="form.type"
                                                :id="`tool${item.id}`"
                                                :value="item.id"
                                                class="custom-control-input"
                                            />
                                            <label :for="`tool${item.id}`" class="custom-control-label">{{ item.name }}</label>
                                        </div>
                                    </div>
                                    <div v-if="submitted && $v.form.type.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.rule.required">{{
                                            $t('validateField.required', {
                                                field: '種別'
                                            })
                                        }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group" v-if="form.type == 3">
                                <label for="" class="col-sm-3">クローズ</label>

                                <div class="col-sm-9">
                                    <div class="d-flex align-items-center">
                                        <div style="position: relative; width: 100%">
                                            <div>
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="value"
                                                    v-model="ruleClose"
                                                    :options="listDataMasterCampaignTypeClose"
                                                ></multiselect>
                                                <i
                                                    v-if="ruleClose.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        ruleClose = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group" v-if="form.type == 3">
                                <label for="" class="col-sm-3">オープン</label>

                                <div class="col-sm-9">
                                    <div class="d-flex align-items-center">
                                        <div style="position: relative; width: 100%">
                                            <div>
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="value"
                                                    v-model="ruleOpen"
                                                    :options="listDataMasterCampaignTypeOpen"
                                                ></multiselect>
                                                <i
                                                    v-if="ruleOpen.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        ruleOpen = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <label for="" class="col-sm-3">詳細条件</label>

                                <div class="col-sm-9">
                                    <div>
                                        <div class="d-flex align-items-center mb-2">
                                            <div style="position: relative; width: 100%">
                                                <multiselect
                                                    :deactivate="true"
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    track-by="id"
                                                    label="detail"
                                                    v-model="dataSelect"
                                                    :options="filteredOptions(-1)"
                                                >
                                                    <template slot="singleLabel" slot-scope="{ option }">
                                                        <div v-html="option.detail"></div>
                                                    </template>
                                                </multiselect>
                                                <i
                                                    v-if="dataSelect.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        dataSelect = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                            <button type="button" @click="add()" class="btn btn-info ml-2" style="white-space: nowrap">追加</button>
                                        </div>
                                    </div>
                                    <template v-for="(item, k) in listData">
                                        <div :key="k">
                                            <div class="d-flex align-items-center mb-2">
                                                <div style="position: relative; width: 100%">
                                                    <multiselect
                                                        :deactivate="true"
                                                        :showPointer="false"
                                                        :showNoResults="false"
                                                        selectedLabel=""
                                                        deselectLabel=""
                                                        placeholder=""
                                                        selectLabel=""
                                                        :showNoOptions="false"
                                                        track-by="id"
                                                        label="detail"
                                                        v-model="listData[k]"
                                                        :options="filteredOptions(k)"
                                                        @close="changeClose(k)"
                                                    >
                                                        <template slot="singleLabel" slot-scope="{ option }">
                                                            <div v-html="option.detail"></div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <button style="min-width: 70px" type="button" @click="deleteObject(k)" class="btn btn-danger ml-2">
                                                    削除
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <button type="button" @click="goEdit()" class="btn btn-light mr-3">キャンセル</button>
                    <button type="submit" @click="register()" class="btn btn-primary">{{ $route.query.id ? '保存' : '登録' }}</button>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style>
.box-select2 .multiselect__input,
.box-select2 .multiselect__single {
    width: 98% !important;
}
</style>
